import { HostListener, HostListenerMixin } from "@adc/shared/src/HostListener";
import { GlobalDataController } from "../../../utilities/global-data-controller";
import { GlobalTranslations } from '@adc/utilities/global-translations';
import {HeaderDataController} from '@adc/utilities/header-data-controller';
import {AuthStatus} from '@adc/utilities/iauthstatusresponse';
import { Log } from '@adc/utilities/logger-controller';
import { TranslationDataController } from '@adc/utilities/translation-data-controller';
import { registerComponent as registerButton } from '@aileron/button';
import '@aileron/button-icon';
import '@aileron/divider';
import { registerComponent as registerGrid } from '@aileron/grid';
import '@aileron/icon';
import { registerComponent as registerLink } from '@aileron/link';
import { registerComponent as registerNavBar } from '@aileron/nav-bar';
import '@aileron/popover';
import { registerComponent as registerTabs } from '@aileron/tabs';
import { registerComponent as registerTextInput } from '@aileron/text-input';
import '@aileron-web/account-dropdown';
import '@aileron-web/language-selector';
import { LitElement, html } from 'lit';
import { customElement, query, property, queryAll } from 'lit/decorators.js';
import { version } from '../package.json';
import { HeaderFeatureToggles } from './header-feature-toggles';
import styles from './styles.scss';
import { HeaderTranslations } from './translations';

registerButton('hp-header');
registerTextInput('hp-header');
registerGrid('hp-header');
registerLink('hp-header');
registerNavBar('hp-header');
registerTabs('hp-header');

/*
* @fires aileron-header-sidenav-beingtoggled - Event fired when the item is being 
* opened closed.
* @fires aileron-header-sidenav-toggled - Event fired when the item is opened or
* closed.
*/

@customElement('adc-header')

export class Header extends HostListenerMixin(LitElement) {
  @property({ type: String, reflect: true }) localeCode = "en_US";
  @property({ type: String, reflect: true }) searchInput = "";

  /**
   * @private
   */
  static styles = styles;
  private startTime: number;
  showSearchField: boolean;
  isInSpa: boolean;
  static fullSiteUrl = '/homePage.do?fullHTMLVersion=true&site_preference=normal';
  private readonly data = new HeaderDataController(this);

  @query('#sidenav')  sidenav;
  @query('#tabs') tabs;
  @queryAll('.unique-dropdown') uniqueDropdown;
  @property({type: Number}) loadTime: number;
  @property() name = 'Header';
  @property({ type: Boolean, reflect: true }) open = false;
  @property({ type: String }) contentId = "aa-content-frame";
  @property({ type: Boolean }) goDark = false;

  constructor() {
    super();
  }

  @Log
  render() {
    this.startTime = new Date().getTime();
    this.localeCode = TranslationDataController.retrieveLocale();
    this.showSearchField = this.showSearch();
    TranslationDataController.getLocaleTranslations(this.localeCode, HeaderTranslations);
    TranslationDataController.getLocaleTranslations(this.localeCode, GlobalTranslations);
    this.isInSpa = !!document.querySelector('app-root');
    this.goDark = this.data?.goDarkData?.goDark ?? false;
    return html`
      <header @click="${this._captureHeaderEvent}" @keydown="${this._captureHeaderEvent}" @dropdown-toggled=${this._dropdownToggled}>
        ${this.displayDesktop(this.data?.menuData)}
        ${this.displayMobile(this.data?.menuData)}
      </header>
    `;
  }

  protected async getUpdateComplete(): Promise<boolean> {
    const result = await super.getUpdateComplete();
    this.loadTime = new Date().getTime() - this.startTime;
    return result;
  }


  displayDesktop(data) { 
    return html`
      <hp-header-grid class="grid-top mobile-view--hidden">
        <adc-row class="lang-selector-search-row">
          <adc-column col-desktop="3">
            <hp-header-link class="skip-link" href="#main-navigation" @click="${this._handleSkipLink}"
                      @keydown="${this._handleSkipLinkKeydown}">${TranslationDataController.translations['SkipToGlobalNavigation']}
            </hp-header-link>
            <hp-header-link class="skip-link" href="#${ this.contentId }" @click="${ this._handleSkipLink }"
                      @keydown="${this._handleSkipLinkKeydown}">${TranslationDataController.translations['SkipToContent']}</hp-header-link>
            <hp-header-link class="skip-link" href="#aa-footer" @click="${this._handleSkipLink}"
                      @keydown="${this._handleSkipLinkKeydown}">${TranslationDataController.translations['SkipToFooter']}
            </hp-header-link>
          </adc-column>
          <adc-column col-desktop="3">
          </adc-column>
          <adc-column class="col-side lng-selector-desktop">
          ${location.href.includes(Header.fullSiteUrl) ? this.showMobileLink() : ''}
            <adc-language-selector class="unique-dropdown" alignDialogRight="${this.showSearchField}"></adc-language-selector>
          </adc-column>
          ${this.showSearchField ? html`<adc-column class="col-side">${this.searchBox()}</adc-column>` : ''}
        </adc-row>
      </hp-header-grid>
      <hp-header-grid class="mobile-view--hidden grid-bottom">
        <adc-row id="main-navigation" class="row-header-mobile">
          <adc-column col-desktop="3" col-phone="2.5" class="col-side logo-column-mobile logo-column">
            <a href="${data?.logo?.href}" aria-label="${TranslationDataController.translations['HomePageLink']}" class="aa-logo-link">
              <img class="aa-logo" src="${data?.logo?.src}" alt="${data?.logo?.ImgAltText}">
            </a>
            <a id="header-one-world-logo"
              href="${data?.oneWorldLogo?.href}"
              hidden-label-text="${(data?.oneWorldLogo?.newWin ? (data?.oneWorldLogo?.external ? data.newWinTextExternal : data.newWinText) : '')}"
              target="${data?.oneWorldLogo?.newWin ? '_blank' : '_self'}">
              <img class="one-world"
                alt="${data?.oneWorldLogo?.ImgAltText}"
                src="${data?.oneWorldLogo?.src}">
            </a>
          </adc-column>
          <adc-column col-desktop="6" class="col-center">
            <hp-header-tabs class="tabs-small mobile-view--hidden" id="tabs">
              ${data?.meganavLinks.menu.map(
                (menu) => html`
                  <adc-tab class="tab-nav"
                           label="${menu.heading}"
                           value="${menu.heading}"
                           @click="${this._handleTabToggle}"
                           @keydown="${this._handleTabKeydown}">${menu.heading}
                  </adc-tab>
                  ${(this.displayTabPanel(menu, data?.meganavLinks.commonLinks, data))}
                `)}
            </hp-header-tabs>
          </adc-column>
          <adc-column class="${this._isSecureUser(this.data.authStatus) ? "profile-container" : ""} button-column"
                      col-desktop="3">
                      ${this.data.isDataFetched ?
                        (this._isSecureUser(this.data.authStatus) ? this.displayAccountDropdown() : this.displayButtons(""))
                        :
                        ''
                      }
          </adc-column>
        </adc-row>
          <adc-divider class="divider-bottom" color="tertiary" size="x-small" spacing="12"></adc-divider>
      </hp-header-grid>`
  }

  displayTabPanel(menu, commonLinks, data) {
    return html`
      <adc-tab-panel value="${menu.heading}" id="${menu.heading}" class="panel-container">
        <hp-header-grid>
          <adc-column class="tab-divider" col-desktop="12">
            <adc-divider color="tertiary" size="x-small" spacing="12"></adc-divider>
          </adc-column>
        </hp-header-grid>
        <hp-header-grid>
          <adc-row has-form="">
            <adc-column col-desktop="3">
            <div>
              <span class="panel-note" .innerHTML="${menu.sideNote}"></span>
            </div>
            </adc-column>
            ${menu.columns.map(
                (column) => html`
                <adc-column col-desktop="3" class="link-col">
                  ${column.Links.map(
                  (link) => html`
                    <hp-header-link class="panel-links"
                      target="${link.newWin ? '_blank' : '_self'}"
                      href="${link.href}"
                      hidden-label-text="${link && link.newWin ? (link.external ? data.newWinTextExternal : data.newWinText) : ''}"
                      icon="${(link.newWin ? 'new-window' : '' )}">
                      ${link.linkText}</hp-header-link>
                    <adc-divider color="secondary" size="x-small" spacing="12"></adc-divider>
                  `)}
                </adc-column>
            `)}
            <hr>
            <adc-column col-desktop="2">
              ${commonLinks.map(
                  (link) => html`
                    <hp-header-link class="common-note"
                      target="${link.newWin ? '_blank' : '_self'}"
                      icon="${(link.newWin ? 'new-window' : '' )}"
                      hidden-label-text="${link && link.newWin ? (link.external ? data.newWinTextExternal : data.newWinText) : ''}"
                      href="${link.href}">
                      ${link.linkText}</hp-header-link>
              `)}
            </adc-column>
          </adc-row>
        </hp-header-grid>
        <hp-header-grid>
          <adc-row>
            <adc-column col-desktop="12">
              <adc-button-icon class="close-menu-icon" value="${menu.heading}" icon="navigation:caret-up" kind="ghost"
              label-text=${TranslationDataController.translations['CloseMenu']} @click="${this._handleTabToggle}"></adc-button-icon>
            </adc-column>
          </adc-row>
        </hp-header-grid>
      </adc-tab-panel>
    `;
  }

  displayMobile(data) {
    return html`
      <hp-header-grid class="mobile-view--visible">
        <adc-row class="row-mobile-header">
          <hp-header-link class="skip-link" href="#main-navigation--mobile" @click="${this._handleSkipLink}"
                    @keydown="${this._handleSkipLinkKeydown}">${TranslationDataController.translations['SkipToGlobalNavigation']}
          </hp-header-link>
          <hp-header-link class="skip-link" href="#${ this.contentId }" @click="${this._handleSkipLink}"
                      @keydown="${this._handleSkipLinkKeydown}">${TranslationDataController.translations['SkipToContent']}</hp-header-link>
          <hp-header-link class="skip-link" href="#aa-footer" @click="${this._handleSkipLink}"
                    @keydown="${this._handleSkipLinkKeydown}">${TranslationDataController.translations['SkipToFooter']}
          </hp-header-link>
        </adc-row>
        <adc-row class="row-mobile-header" id="main-navigation--mobile">
          <adc-column col-phone="2" class="logo-column-mobile">
            <a href="${data?.logo?.href}" aria-label="${TranslationDataController.translations['HomePageLink']}">
              <img class="aa-logo" src="${data?.logo?.src}" alt="${data?.logo?.ImgAltText}">
            </a>
          </adc-column>
          <adc-column col-phone="2" class="buttons-mobile ">
            ${this.data.isDataFetched ?
              (this._isSecureUser(this.data.authStatus) ? this.displayAccountDropdown() : this.displayButtons("sm"))
              :
              ''
            }
            <adc-button-icon class="operation-menu" icon="operation:menu" kind="ghost" label-text=${TranslationDataController.translations['MobileNav']}
                             @click="${this._handleClick}"></adc-button-icon>
            <div class="sidenav-overlay" ?hidden='${!this.open}' @click="${this._handleClick}"
                 @keyDown="${this._handleClick}"></div>
            <div class="sidenav" id="sidenav">
              ${this.showSearchField ? html`<div class="search-mobile">${this.searchBox()}</div>` : ''}
              ${data?.meganavLinks.commonLinks.map(
                (link) => html`
                  <hp-header-link class="nav-links"
                    icon="chevron"
                    href="${link.href}">
                    ${link.linkText} ${(link.newWin ? this.showNewWindow(link, data) : '')}
                  </hp-header-link>
                `)}
              <div class="divider-container">
                <adc-divider class="divider" color="tertiary" size="x-small" spacing="12"></adc-divider>
              </div>
              <hp-header-nav-bar class="nav-bar">
                ${data?.meganavLinks.menu.map(
                  (menu) => html`
                    <adc-nav-item id="mobile-nav-bar" class="nav-item"
                                  label-text="${menu.heading}"
                    >
                      ${menu.columns.map((column) => html`
                        ${column.Links.map(
                          (link) => html`
                            <div>
                              <hp-header-link class="nav-links" icon="chevron" href="${link.href}" >
                                ${link.linkText} ${(link.newWin ? this.showNewWindow(link, data) : '')}
                              </hp-header-link>
                            </div>
                          `)}
                      `)}
                    </adc-nav-item>
                  `)}
              </hp-header-nav-bar>
            </div>
          </adc-column>
        </adc-row>
        <adc-divider color="tertiary" spacing="16" size="small"></adc-divider>
        <adc-row class="row-mobile-language">
          <adc-column col-phone="2" class="travel-column">
          ${!this.isInSpa && GlobalDataController._isHomePage() ? this.travelAlert() : ''}
          </adc-column>
          <adc-column class="lng-selector-mobile" col-phone="2">
            <adc-language-selector class="unique-dropdown" alignDialogRight="${this.showSearchField}"></adc-language-selector>
          </adc-column>
        </adc-row>
      </hp-header-grid>
    `
  }

  travelAlert() {
    return html`
      <hp-header-link class="travelAlert" href="/i18n/travel-info/travel-alerts.jsp" icon="chevron" >
        <adc-icon icon="signal:warning"></adc-icon>${TranslationDataController.translations['TravelAlerts']}
      </hp-header-link>
    `
  }

  showNewWindow(link, data) {
    return html`
      <hp-header-link class="nav-links"
        href="${link.href}"
        icon="${(link.newWin ? 'new-window' : '')}"
        hidden-label-text="${link && link.newWin ? (link.external ? data.newWinTextExternal : data.newWinText) : ''}"
        target="${link.newWin ? '_blank' : '_self'}">
      </hp-header-link>
    `
  }

  showMobileLink() {
    return html`
      <hp-header-link class="mobile-link" href="/homePage.do?site_preference=mobile">Mobile</hp-header-link>
    `
  }

  private _handleTabKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      this._handleTabToggle(event);
    }
    if (event.key === 'Tab') {
      this._resetTabIndex();
    }
  }

 /**
   * @private
   * @param event
   */
 @HostListener("document:click")
 // @ts-expect-error - Not called because of @HostListener
  private readonly _handleTabClick = (event: MouseEvent) => {
    if (event.target !== this) {
     this._dropdownToggled(new CustomEvent('dropdown-toggled', {bubbles: true, composed: true, detail:""}));
    }
  };

  // Loops through the uniqueDropdown query and triggers the function headerToggleDropdown in their respective components
  private _dropdownToggled(event) {
    this.uniqueDropdown?.forEach(dropdown => {
      dropdown.headerToggleDropdown(event);
    });
  }

  // Any element that does not have a class name of unique-dropdown is reset
  private  _captureHeaderEvent(event){
    event.stopPropagation();
    const hasUniqueDropdownClass = event.target.classList.contains('unique-dropdown');

    if(!hasUniqueDropdownClass) {
      this._dropdownToggled(new CustomEvent('dropdown-toggled', {bubbles: true, composed: true, detail:""}));
    }
  }

  /**
   * Checks if the tab that the user clicked on is already opened.
   * if already opened, edits adc-tabs attributes to close the tab
   * otherwise allows adc-tabs to follow its default behavior
   **/
  private _handleTabToggle(event) {
    const tabsValue = event.target.getAttribute('value');
    if (this.tabs.selected === tabsValue) {
      setTimeout(()=> {
        this.tabs.selected = '';
      }, 10);
      this._resetTabIndex();
    }
  }

  private _resetTabIndex() {
    setTimeout(() => {
      const firstTab = [...(this.tabs.querySelectorAll("adc-tab"))][0].renderRoot.querySelector("button");
      firstTab.setAttribute("tabindex", "0");
      }, 100);
  }

  private _handleSkipLink(event) {
    event.preventDefault();
    if(event.target.href === '#main-navigation' || event.target.href === '#main-navigation--mobile') {
      this._addScrollIntoView(event, 'adc-header');
    } else if(event.target.href === '#aa-footer') {
      this._addScrollIntoView(event, 'adc-footer');
    } else if(event.target.href === `#${  this.contentId}`) {
      event.target.blur();
      const mainContent = document.querySelector(`#${this.contentId}`);
      mainContent?.scrollIntoView({behavior: 'smooth', block: 'nearest'});
    }
  }

  private _addScrollIntoView(event, selector) {
    event.target.blur();
    const shadowRoot = document.querySelector(selector)?.shadowRoot;
    const component = shadowRoot?.querySelector(event.target.href);
    component.scrollIntoView({behavior: 'smooth'});
  }

  private _handleSkipLinkKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      this._handleSkipLink(event);
    }
  }

  private _handleClick() {
    this._handleUserInitiatedToggle();
    if (this.open) {
      document.body.style.right = "60vw";
      document.body.style.position = "relative";
      this.sidenav.style.right = "0";
    } else {
      document.body.style.right = "0";
      document.body.style.position = "";
      this.sidenav.style.right = "-60vw";
    }
  }

  private _handleUserInitiatedToggle(open = !this.open) {
    const init = {
      bubbles: true,
      cancelable: true,
      composed: true,
      detail: {
        open
      }
    };

    if (
      this.dispatchEvent(
        new CustomEvent((this.constructor as typeof Header).eventBeforeToggle, init)
      )
    ) {
        this.open = open;
        this.dispatchEvent(
          new CustomEvent((this.constructor as typeof Header).eventToggle, init)
        );
    }
  }

  displayAccountDropdown() {
    return html`<adc-account-dropdown class="aa-account unique-dropdown"></adc-account-dropdown>`;
  }

  displayPopOver() {
    return html`
      <adc-popover heading="${TranslationDataController.translations['LogInPopoverHeading']}" kind="secondary" placement="bottom" class="hidden--tablet-down popover ${this.isInSpa ? 'popover-spa' : ''}">
        ${TranslationDataController.translations['LogInPopoverBody']}
      </adc-popover>`;
  }

  displayButtons(size: "" | "sm") {

    let shouldShowButtons = true;
    const rollup = TranslationDataController.getLocaleRollup(this.localeCode);
    const locales = [this.localeCode, ...(rollup || [])];
    locales.forEach(locale => {
      if(HeaderFeatureToggles.HIDELOYALTYBUTTONS.includes(locale)) {
        shouldShowButtons = false;
        return;
      }
    });

    if(HeaderFeatureToggles.LOYALTYBUTTONOVERRIDE.includes(this.localeCode)) {
      shouldShowButtons = true;
    }

    if(shouldShowButtons) {
      return html`
        ${this._checkLoginPage() ? '' : this.displayLoginButton(size)}
        ${GlobalDataController._isHomePage() ? [!this.goDark ? this.displayPopOver() : '', this.displayJoinButton(size)] : ''}`;
    }
    return html``;

  }

  displayLoginButton(size: "" | "sm") {
    return html`
     <hp-header-button class="button-login button" size=${size} type="button" kind="primary" href="${this._getLoginUrl()}">${TranslationDataController.translations['Login']}</hp-header-button>`;
  }

  displayJoinButton(size: "" | "sm") {
    return html`
      <hp-header-button class="button-join button" size="${size}" type="button" id="join" kind="secondary" href="/loyalty/enrollment/enroll?from=comp_nav">${TranslationDataController.translations['Join']}</hp-header-button>`;
  }

  private _checkLoginPage(): boolean {
    return (window.location.pathname.includes('/loyalty/login') || window.location.pathname.includes('/web/loyalty/profile/welcome'));
  }

  showSearch() {
    let shouldShowSearch = false;
    const rollup = TranslationDataController.getLocaleRollup(this.localeCode);
    const locales = [this.localeCode, ...(rollup || [])];
    locales.forEach(locale => {
      if(HeaderFeatureToggles.SHOWSEARCH.includes(locale)) {
        shouldShowSearch = true;
        return;
      }
    });

    return shouldShowSearch;

  }

  searchBox() {
    return html`
      <hp-header-text-input autocomplete="off" name="input" id="searchInput" a11y-label=${ TranslationDataController.translations['SearchAACom'] } placeholder=${TranslationDataController.translations['SearchAACom']} type="text" validity-message="Validity message" value="" @keyup=${this.searchValue}>
          <adc-button-icon slot="button-icon" icon="operation:search-alt" @click=${this.onSubmit} @keyup=${this.searchValue} label-text=${TranslationDataController.translations['SubmitSearch']} ></adc-button-icon>
      </hp-header-text-input>`;
  }

  /**
  * The name of the custom event fired before this accordion item is being
  * toggled upon a user gesture. Cancellation of this event stops the
  * user-initiated action of toggling this  accordion item.
  */
  static get eventBeforeToggle(): string {
    return "aileron-header-sidenav-beingtoggled";
  }

  /**
   * The name of the custom event fired after this accordion item is toggled 
   * upon a user gesture.
   */
  static get eventToggle(): string {
    return "aileron-header-sidenav-toggled";
  }

  get version(): string {
    return version
  }

  searchValue(event): void {
    const keycode = event.keyCode;

    if (
      (keycode > 47 && keycode < 58) ||
      keycode === 32 ||
      keycode === 13 ||
      (keycode > 64 && keycode < 91) ||
      (keycode > 95 && keycode < 112) ||
      (keycode > 185 && keycode < 193) ||
      (keycode > 218 && keycode < 223)
    ) {
      this.searchInput = event.target.value;
    }
    if (event.key === "Enter") {
      this.onSubmit();
    }
  }

  /**
   * Updates url based on search input
   */
  onSubmit() {
    if (this.searchInput) {
      window.location.href = `/search#?query=${this.searchInput}`;
    }
  }

  private _getLoginUrl(): string {
    return (`/loyalty/login?uri=%2floyalty%2flogin&previousPage=${this._buildContinueUrl()}&from=comp_nav`);
  }

  private _buildContinueUrl(): string {
    let continueUrl = '';
    const encodedURI = encodeURIComponent(location.pathname);
    let queryString = location.search?.substring(1);
    if (queryString.includes('&from=Nav')){
      queryString = queryString.replace('&from=Nav', '');
    }
    continueUrl = continueUrl.concat(encodedURI);
    if (queryString && queryString !== '') {
      continueUrl = continueUrl.concat(`%3F${queryString}`); // encoded "?" followed by query string
    }
    return continueUrl;
  }

  private _isSecureUser(authStatus: AuthStatus): boolean {
    return authStatus === AuthStatus.SECURED;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "adc-header": Header;
  }
}
